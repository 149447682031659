// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error_404_wrapper {
  padding-top: 112px;
  padding-bottom: 89px;
  z-index: 111;
  position: relative;
  background: #fff;
}
.error404_img img {
  max-width: 618px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.error404_outer {
  text-align: center;
  margin: 0 auto;
  max-width: 618px;
  width: 100%;
}
.error404_title h2 {
  color: #022539;
  margin: 0;
}
.error404_title h3 {
  color: #022539;
  margin: 0;
}
.error404_title h3 {
  margin-top: 20px;
}
.comin_soon_img {
  max-width: 571px;
  width: 100%;
  border: 10px solid #fff;
  border-radius: 10px;
}
.blog_detail_title h3 + p {
  margin-top: 41px;
}
.blog_detail_title p + p {
  margin-top: 15px;
}
.blog_detail_title p {
  max-width: 1000px;
  width: 100%;
}
.error_404_wrapper .troo_da_about_we_r_done_btn button.btn.btn-primary {
  padding: 17px 15px 17px 15px;
}
.error_404_wrapper .troo_da_about_we_r_done_btn {
  padding-top: 20px;
}
.error404_img {
  padding-bottom: 66px;
}

@media (max-width : 991px){
  .error_404_wrapper { padding-top: 50px; padding-bottom: 25px; } 
    .error404_img { padding-bottom: 25px; }
}`, "",{"version":3,"sources":["webpack://./src/Components/Error404Comp/Error404Component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,cAAc;EACd,gBAAgB;EAChB,WAAW;AACb;AACA;EACE,cAAc;EACd,SAAS;AACX;AACA;EACE,cAAc;EACd,SAAS;AACX;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,WAAW;EACX,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,WAAW;AACb;AACA;EACE,4BAA4B;AAC9B;AACA;EACE,iBAAiB;AACnB;AACA;EACE,oBAAoB;AACtB;;AAEA;EACE,qBAAqB,iBAAiB,EAAE,oBAAoB,EAAE;IAC5D,gBAAgB,oBAAoB,EAAE;AAC1C","sourcesContent":[".error_404_wrapper {\r\n  padding-top: 112px;\r\n  padding-bottom: 89px;\r\n  z-index: 111;\r\n  position: relative;\r\n  background: #fff;\r\n}\r\n.error404_img img {\r\n  max-width: 618px;\r\n  width: 100%;\r\n  height: 100%;\r\n  object-fit: contain;\r\n}\r\n.error404_outer {\r\n  text-align: center;\r\n  margin: 0 auto;\r\n  max-width: 618px;\r\n  width: 100%;\r\n}\r\n.error404_title h2 {\r\n  color: #022539;\r\n  margin: 0;\r\n}\r\n.error404_title h3 {\r\n  color: #022539;\r\n  margin: 0;\r\n}\r\n.error404_title h3 {\r\n  margin-top: 20px;\r\n}\r\n.comin_soon_img {\r\n  max-width: 571px;\r\n  width: 100%;\r\n  border: 10px solid #fff;\r\n  border-radius: 10px;\r\n}\r\n.blog_detail_title h3 + p {\r\n  margin-top: 41px;\r\n}\r\n.blog_detail_title p + p {\r\n  margin-top: 15px;\r\n}\r\n.blog_detail_title p {\r\n  max-width: 1000px;\r\n  width: 100%;\r\n}\r\n.error_404_wrapper .troo_da_about_we_r_done_btn button.btn.btn-primary {\r\n  padding: 17px 15px 17px 15px;\r\n}\r\n.error_404_wrapper .troo_da_about_we_r_done_btn {\r\n  padding-top: 20px;\r\n}\r\n.error404_img {\r\n  padding-bottom: 66px;\r\n}\r\n\r\n@media (max-width : 991px){\r\n  .error_404_wrapper { padding-top: 50px; padding-bottom: 25px; } \r\n    .error404_img { padding-bottom: 25px; }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
