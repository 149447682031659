// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/SkeletonLoader.css */
.skeleton-loader {
    padding: 20px;
  }
  
  .skeleton-box {
    background-color: #e0e0e0;
    border-radius: 4px;
    margin-bottom: 10px;
    animation: pulse 1.5s infinite ease-in-out;
  }
  
  .skeleton-box.title {
    height: 20px;
    width: 60%;
  }
  
  .skeleton-box.content {
    height: 15px;
    width: 100%;
  }
  
  @keyframes pulse {
    0% {
      background-color: #e0e0e0;
    }
    50% {
      background-color: #c0c0c0;
    }
    100% {
      background-color: #e0e0e0;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/AdvancedSkeletonLoader.css"],"names":[],"mappings":"AAAA,sCAAsC;AACtC;IACI,aAAa;EACf;;EAEA;IACE,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;IACnB,0CAA0C;EAC5C;;EAEA;IACE,YAAY;IACZ,UAAU;EACZ;;EAEA;IACE,YAAY;IACZ,WAAW;EACb;;EAEA;IACE;MACE,yBAAyB;IAC3B;IACA;MACE,yBAAyB;IAC3B;IACA;MACE,yBAAyB;IAC3B;EACF","sourcesContent":["/* src/components/SkeletonLoader.css */\n.skeleton-loader {\n    padding: 20px;\n  }\n  \n  .skeleton-box {\n    background-color: #e0e0e0;\n    border-radius: 4px;\n    margin-bottom: 10px;\n    animation: pulse 1.5s infinite ease-in-out;\n  }\n  \n  .skeleton-box.title {\n    height: 20px;\n    width: 60%;\n  }\n  \n  .skeleton-box.content {\n    height: 15px;\n    width: 100%;\n  }\n  \n  @keyframes pulse {\n    0% {\n      background-color: #e0e0e0;\n    }\n    50% {\n      background-color: #c0c0c0;\n    }\n    100% {\n      background-color: #e0e0e0;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
