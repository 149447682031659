import React, { useState , useEffect} from "react";
import "./AboutComponent.css";
import fetchAboutHighlight from  "../../api/about/aboutHighlightAPI"


const AboutHighlight = () => {
  const [highlightData, setHighlightData] = useState(null)
  
  useEffect(() => {
    const getHighlightCards = async () => {
      try {
        const cards = await fetchAboutHighlight();
        setHighlightData(cards);
      } catch (error) {
        console.error("Failed to fetch highlight cards:", error);
      }
    };

    getHighlightCards();
  }, []);


  if (!highlightData) {
    return <div>Loading...</div>; // Add a fallback while data is being fetched
  }
  
  return (
    <section class="troo_da_detail_box_wrapper">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="troo_da_detail_box">
              <div class="troo_da_detail_box_img">
                <svg
                  width="56"
                  height="62"
                  viewBox="0 0 56 62"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.25 49.9584H36.75M13.4167 39.75H42.5833M7.58333 1.83337H48.4167C51.6383 1.83337 54.25 4.73523 54.25 8.31486V53.6852C54.25 57.2648 51.6383 60.1667 48.4167 60.1667H7.58333C4.36167 60.1667 1.75 57.2648 1.75 53.6852V8.31485C1.75 4.73523 4.36167 1.83337 7.58333 1.83337ZM27.993 14.1182C25.952 11.8457 22.5485 11.2344 19.9913 13.3153C17.434 15.3962 17.074 18.8754 19.0822 21.3365C21.0904 23.7976 27.993 29.5417 27.993 29.5417C27.993 29.5417 34.8956 23.7976 36.9038 21.3365C38.912 18.8754 38.596 15.3744 35.9948 13.3153C33.3936 11.2563 30.034 11.8457 27.993 14.1182Z"
                    stroke="#022539"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="troo_da_detail_box_title">
                <h3>{ highlightData[0].title }</h3>
              </div>
              <div class="troo_da_detail_box_content">
                <p>
                { highlightData[0].description }
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="troo_da_detail_box">
              <div class="troo_da_detail_box_img hover">
                <svg
                  width="70"
                  height="70"
                  viewBox="0 0 70 70"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.9503 45.0915L20.4167 64.1667L33.7995 56.137C34.236 55.8751 34.4542 55.7442 34.6872 55.693C34.8933 55.6478 35.1067 55.6478 35.3128 55.693C35.5458 55.7442 35.764 55.8751 36.2005 56.137L49.5833 64.1667L47.0514 45.0833M47.9088 12.3926C48.3593 13.482 49.2239 14.348 50.3126 14.8001L54.1302 16.3814C55.2197 16.8327 56.0853 17.6984 56.5365 18.7879C56.9878 19.8774 56.9878 21.1016 56.5365 22.1911L54.9564 26.0061C54.5049 27.0961 54.5043 28.3215 54.9578 29.4109L56.5352 33.2248C56.7589 33.7644 56.8741 34.3428 56.8742 34.927C56.8743 35.5112 56.7593 36.0896 56.5358 36.6293C56.3122 37.169 55.9845 37.6594 55.5714 38.0724C55.1583 38.4854 54.6679 38.8129 54.1281 39.0363L50.3133 40.6165C49.2239 41.067 48.358 41.9316 47.9059 43.0204L46.3246 46.8381C45.8733 47.9276 45.0077 48.7932 43.9182 49.2445C42.8288 49.6958 41.6046 49.6958 40.5152 49.2445L36.7003 47.6643C35.6108 47.2141 34.3871 47.2151 33.2983 47.6669L29.4807 49.246C28.3918 49.6962 27.1688 49.6958 26.0802 49.2449C24.9916 48.794 24.1265 47.9294 23.6749 46.841L22.0931 43.0222C21.6427 41.9327 20.7781 41.0668 19.6894 40.6147L15.8718 39.0333C14.7828 38.5822 13.9174 37.7172 13.466 36.6283C13.0145 35.5394 13.0139 34.3158 13.4643 33.2264L15.0445 29.4115C15.4946 28.3219 15.4937 27.0982 15.0419 26.0093L13.464 22.1888C13.2403 21.6492 13.1251 21.0708 13.125 20.4866C13.1249 19.9025 13.2399 19.324 13.4635 18.7843C13.687 18.2446 14.0147 17.7543 14.4278 17.3413C14.8409 16.9283 15.3314 16.6007 15.8711 16.3774L19.686 14.7971C20.7744 14.3471 21.6398 13.4835 22.0922 12.396L23.6735 8.57827C24.1248 7.48875 24.9904 6.62314 26.0799 6.17184C27.1693 5.72055 28.3935 5.72055 29.4829 6.17184L33.2978 7.75206C34.3873 8.20225 35.611 8.20133 36.6998 7.74951L40.519 6.17429C41.6083 5.72325 42.8322 5.72335 43.9215 6.17455C45.0107 6.62575 45.8762 7.49112 46.3275 8.58036L47.9093 12.3992L47.9088 12.3926Z"
                    stroke="#022539"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="troo_da_detail_box_title">
                <h3>{ highlightData[1].title }</h3>
              </div>
              <div class="troo_da_detail_box_content">
                <p>
                { highlightData[1].description }
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="troo_da_detail_box">
              <div class="troo_da_detail_box_img">
                <svg
                  width="62"
                  height="62"
                  viewBox="0 0 62 62"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M36.9782 13.5C39.827 14.0559 42.4451 15.4491 44.4975 17.5015C46.5499 19.5539 47.9432 22.1721 48.499 25.0209M36.9782 1.83337C42.8969 2.4909 48.4161 5.14138 52.6297 9.34965C56.8433 13.5579 59.5007 19.0738 60.1657 24.9917M25.8277 36.434C22.3231 32.9294 19.5558 28.9667 17.5258 24.7386C17.3512 24.3749 17.2639 24.1931 17.1968 23.963C16.9584 23.1453 17.1296 22.1412 17.6255 21.4487C17.7651 21.2539 17.9318 21.0871 18.2652 20.7537C19.2849 19.734 19.7947 19.2242 20.128 18.7115C21.3851 16.7781 21.3851 14.2856 20.128 12.3521C19.7947 11.8394 19.2849 11.3296 18.2652 10.3099L17.6968 9.74155C16.1468 8.19151 15.3717 7.41649 14.5394 6.99549C12.884 6.1582 10.929 6.1582 9.27366 6.99549C8.4413 7.41649 7.66628 8.19151 6.11624 9.74155L5.65647 10.2013C4.11174 11.7461 3.33937 12.5184 2.74948 13.5685C2.09492 14.7337 1.62428 16.5435 1.62826 17.88C1.63184 19.0844 1.86548 19.9076 2.33275 21.5539C4.84391 30.4013 9.58196 38.7498 16.5469 45.7148C23.5118 52.6797 31.8604 57.4178 40.7078 59.9289C42.3541 60.3962 43.1773 60.6298 44.3817 60.6334C45.7182 60.6374 47.5279 60.1668 48.6932 59.5122C49.7433 58.9223 50.5156 58.1499 52.0604 56.6052L52.5201 56.1454C54.0702 54.5954 54.8452 53.8204 55.2662 52.988C56.1035 51.3326 56.1035 49.3777 55.2662 47.7223C54.8452 46.8899 54.0702 46.1149 52.5201 44.5649L51.9518 43.9965C50.9321 42.9768 50.4222 42.467 49.9095 42.1336C47.9761 40.8766 45.4836 40.8766 43.5502 42.1336C43.0375 42.467 42.5276 42.9768 41.5079 43.9965C41.1745 44.3299 41.0078 44.4966 40.813 44.6362C40.1205 45.132 39.1164 45.3032 38.2987 45.0649C38.0686 44.9978 37.8867 44.9105 37.523 44.7359C33.295 42.7059 29.3323 39.9386 25.8277 36.434Z"
                    stroke="#022539"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="troo_da_detail_box_title">
                <h3>{ highlightData[2].title }</h3>
              </div>
              <div class="troo_da_detail_box_content">
                <p>
                { highlightData[2].description }
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutHighlight;
