// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.brand_logo img {
  max-width: 170px;
  width: 100%;
  height: 75px;
  margin: 0 auto;
}
.brand_logo_wrapper {
  padding-top: 50px;
  padding-bottom: 50px;
}
.brand_logo_outer.d-flex{
    column-gap: 35px;
    justify-content: center;
}
@media (max-width : 767px){
  .brand_logo_outer.d-flex{
    column-gap: 0;
  }
}
@media (max-width : 550px){
  .brand_logo_outer.d-flex{
  flex-direction: column;
  }
  .brand_logo_outer.d-flex .item{
    display: flex;
    justify-content: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/BrandLogo/BrandLogo.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,cAAc;AAChB;AACA;EACE,iBAAiB;EACjB,oBAAoB;AACtB;AACA;IACI,gBAAgB;IAChB,uBAAuB;AAC3B;AACA;EACE;IACE,aAAa;EACf;AACF;AACA;EACE;EACA,sBAAsB;EACtB;EACA;IACE,aAAa;IACb,uBAAuB;EACzB;AACF","sourcesContent":[".brand_logo img {\r\n  max-width: 170px;\r\n  width: 100%;\r\n  height: 75px;\r\n  margin: 0 auto;\r\n}\r\n.brand_logo_wrapper {\r\n  padding-top: 50px;\r\n  padding-bottom: 50px;\r\n}\r\n.brand_logo_outer.d-flex{\r\n    column-gap: 35px;\r\n    justify-content: center;\r\n}\r\n@media (max-width : 767px){\r\n  .brand_logo_outer.d-flex{\r\n    column-gap: 0;\r\n  }\r\n}\r\n@media (max-width : 550px){\r\n  .brand_logo_outer.d-flex{\r\n  flex-direction: column;\r\n  }\r\n  .brand_logo_outer.d-flex .item{\r\n    display: flex;\r\n    justify-content: center;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
